.selected {
  background-color: #EDD6AD;
  color: #6ca68d;
}

.calendar {
  h2 {
    font-size: 1.2em;
    text-align: center;
  }

  table {
    border-collapse: separate;
    border-spacing: 2px 2px;
  }

  th {
    text-align: center;
  }

  tr.current-week {
    background-color: #93cfb7;
  }

  td.day {
    position: relative;
    width: 90px;
    height: 80px;
    border: 1px solid #aaa;

    .dow {
      position: absolute;
      top: 4px;
      left: 4px;
      color: #666;
    }

    .event {
      font-size: 0.8em;
    }
  }
  td.next-month .dow {
    color: #aaa;

  }
}

a.day-change {
  display: inline;
  padding: 0 8px;
}

#assignment-list {
  position: relative;
  h2, h3 {
    font-size: 1em;
    text-align: center;
  }

  h3 {
    border-bottom: 1px solid #80bdff;
    padding-bottom: 7px;
  }

  .assignment {
    margin-bottom: 1em;
  }

  .grade, .subject {
    display: inline-block;
    border-radius: 0.8em;
    padding: 0 0.4em;
    margin-right: 0.8em;
  }

  .assigned_date {
    display:inline-block;
  }

  .subject {
    background-color: #99d9c0;
  }

  .description {
    display: none;
  }
}
