@import "lib/bootstrap/scss/bootstrap";

body {
  font-family: 'Titillium Web', sans-serif;
  background-color: #f0efdb;
  font-size: 13px;
}

#main-header {
  background-color: rgb(196, 90, 92);
  padding-bottom: 1rem;
}

#logo-nav {
  padding-top: 0.3em;
  position: relative;
  width: 100%;

  img {
    height: 70px;
    display: inline-block;
  }

  .logo-text {
    margin-left: 10px;
    display: inline-block;
    position: absolute;
    bottom: 6px;
    left: 70px;
    color: rgb(252, 252, 252);
    font-weight: 400;
    font-size: 19px;
    letter-spacing: 1.02px;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1em;
  }
}

.select-grades {
  padding-left: 0;
  list-style: none;

  li {
    display: inline-block;
  }
}

.grade-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid #aaa;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.g5_selected {
  background-color: #4cbcbf;
  color: white !important;
}

.g6_selected {
  background-color: #3162e5;
  color: white !important;
}

.g7_selected {
  background-color: #503977;
  color: white !important;
}

.g8_selected {
  background-color: #932c8c;
  color: white !important;
}

@import "_calendar";

.legend {
  position: absolute;
  right: 0;
  .legend-title {
    font-weight: bold;
    margin-right: 1em;
    display: inline-block;
  }
  .item {
    display: inline-block;
    margin-right: 1em;

    .color {
      display: inline-block;
      height: 1em;
      width: 1em;
      border: 1px solid #999;
      margin-right: 0.4em;
    }

    .current-week {
      background-color: #93cfb7;
    }

    .selected {
      background-color: #EDD6AD;
    }
  }
}
